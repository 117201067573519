import { format } from "date-fns";
import { useState } from "react";
import { useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { getAnnouncements } from "./local_api/ApiService";

function News() {
  const settings = {
    lazyload: true,
    axis: "vertical",
    items: 3,
    autoplay: true,
    mouseDrag: true,
    controls: false,
    autoplayButtonOutput: false,
    nav: false,
  };


  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    getAnnouncements()
      .then((data) => {
        setAnnouncements(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error in YourComponent:", error);
      });
  }, []);


  return (
    <section className="section" id="review" role="region" aria-label="Announcements Section">
      <div className="container">
        <div className="row justify-content-center mb-4" data-aos="fade-up">
          <div className="col-lg-8 text-center">
            <h1 className="Announcement">Announcements</h1>
          </div>
        </div>
        <div data-aos="fade-down">
          <div className="row">
            <div className="col-12 mt-4 pt-2">
              <div className="client-slider-one" role="region" aria-label="Announcements Carousel">

                <TinySlider settings={settings}>
                  {announcements.map((announcement) => (
                    <div key={announcement.id} className="border client-box">
                      <div className="row align-items-center">
                        <div className="col-lg-4 col-xs-4 col-4 align-middle border-end name-box">
                          <h1 className="text-white fs-18 text-truncate mt-4">
                            {format(new Date(announcement.date), "d")}
                          </h1>
                          <p className="mb-3 date">{format(new Date(announcement.date), "MMMM")}</p>
                        </div>
                        <div className="col-lg-8 col-xs-8 col-8">
                          <h2 className="heading">{announcement.title}</h2>
                          <p className="ps-lg-3 detail">{announcement.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </TinySlider>

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <a href="/news" className="btn btn-primary view">
                View All
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <a className="face-button" href="/files/Brochure.pdf" target="_blank" rel="noopener noreferrer">
                <div className="face-primary">
                  <span className="icon fa fa-download"></span>
                  Download Our Brochure
                </div>
                <div className="face-secondary">
                  <span className="icon fa fa-hdd-o"></span>
                  Size: 4.80 MB
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default News;