import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  SwiperCore,
  Autoplay,
} from "swiper";
import "swiper/css/bundle";
import background from "./assets/background.jpg";
import NextGen from "./assets/NextGen.png";

import "./Quotes.css";

function Quotes() {
  return (
    <div>
      <section
        id="quotes"
        className="quotes"
        data-aos="fade-down"
        role="region"
        aria-label="Golden Quotes"
      >
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <div className="quotes-item">
              <h1>GOLDEN QUOTES</h1>

              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                "Opportunities don't happen. You create them." -- Chris Grosser
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="quotes-item">
              <h1>GOLDEN QUOTES</h1>

              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                "I find that the harder I work, the more luck I seem to have."
                -- Thomas Jefferson
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination"></div>
      </section>
      <section id="clients" className="clients" role="region" aria-label="Our Partners">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Partner</h2>
          </div>

          <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">
            <div className="d-flex justify-content-center">
              <div className="client-logo">
                <a
                  href="http://nextgendigitech.nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Visit Nextgen Digitech website"
                >
                  <img src={NextGen} className="img-fluid" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default Quotes;
