import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";

import "aos/dist/aos.css";

import "./BasicComputingCourse.css";

function CertificatePage() {
  AOS.init();
  return (
    <>
      <Navbar />

      {/* Slider start */}

      {/* Slider end */}

      {/* course info start */}
      <section>
        <div id="parallax-world-of-ugg">
          <div className="container">
            <div className="section-title text-center">
              <h2>Course Overview</h2>
            </div>
            <div className="block">
              <p>
                ISTQB (International Software Testing Qualifications Board) has
                defined the “ISTQB Certified Tester” scheme that has become the
                world-wide leader in the certification of competences in
                software testing. ISTQB enables testers to progress their career
                through a Professionals’ Code of Ethics and a multi-level
                certification pathway that provides them with the skills and
                knowledge they need to fulfil their growing responsibilities and
                to achieve increased professionalism.<br></br>
                <br></br>
                At the end of the Software Testing with Selenium course, if the
                student is interested to sit for ISTQB Foundation level
                certification exam, QA Tek will guide the student to achieve the
                goal.<br></br>
                <br></br>
                <strong>ISTQB Certification Fees:</strong> Exam fee $250 + Exam
                center fee (varies from $50 to $100) (A student will directly
                pay the exam fees and exam center fees to ISTQB authorized
                providers.)
              </p>
              <p className="line-break margin-top-10" />
              <p className="margin-top-10">
                Please go to the following link to check ISTQB Levels and
                modules –{" "}
                <a
                  href="https://www.istqb.org/certification-path-root/model-based-tester.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  ISTQB Certifications
                </a>{" "}
                For more information please{" "}
                <a
                  href="/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* course info end */}

      {/* Tab start */}

      {/* Tab end */}

      {/* Price start */}

      {/* Price end */}

      {/* table start */}

      {/* table end */}

      {/* tools start */}

      {/* tools end */}
      <Footer />
    </>
  );
}

export default CertificatePage;
