import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
const formSubmit = (data, callback) => {
    const url = "/records/api/submit-registration/";
    let apiResponse = { response: null, status: null, error: false, msg: "" };

    axios({
        method: "POST",
        url: url,
        data: data,
        responseType: "json",
        responseEncoding: "utf8",
    })
        .then((response) => {
            if (response.status === 201) {
                apiResponse.status = response.status;
                console.log("API Success", response);
            }
        })
        .catch((error) => {
            console.log(error.message);
            apiResponse.status = error.response.status;
            // if (error.response.status === 404) {
            //   console.log("404 Not Found");
            // } else if (error.response.status === 500) {
            //   console.log("500 internal server error");
            // } else if (error.response.status === 403) {
            //   console.log("403 Forbidden ");
            // } else {
            //   console.log("API FAILED", error);
            // }
        })
        .finally(() => {
            callback(apiResponse);
        });
};

const trialSubmit = (data, callback) => {
    const url = "records/api/submit-trial-class/";
    let apiResponse = { response: null, status: null, error: false, msg: "" };
    axios({
        method: "POST",
        url: url,
        data: data,
        responseType: "json",
        responseEncoding: "utf8",
    })
        .then((response) => {
            if (response.status === 201) {
                apiResponse.status = response.status;

                console.log("API Success", response);
            }
        })
        .catch((error) => {
            console.log(error.message);
            apiResponse.status = error.response.status;
            // if (error.response.status === 404) {
            //   console.log("404 Not Found");
            // } else if (error.response.status === 500) {
            //   console.log("500 internal server error");
            // } else if (error.response.status === 403) {
            //   console.log("403 Forbidden ");
            // } else {
            //   console.log("API FAILED", error);
            // }
        })
        .finally(() => {
            callback(apiResponse);
        });
};

export { formSubmit, trialSubmit };