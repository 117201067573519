import YoutubeEmbed from "./YoutubeEmbed";
import "./YoutubeEmbed.css";
import "./About.css";

function About() {
  return (
    <section id="about" className="about" role="region" aria-label="About Us Section">
      <div className="container about-container">
        <div className="row">
          <div className="col">
            <header className="section-title about-tag">
              <h2>Who we are</h2>
              <p>
                <strong>QA Tek Solutions Inc.</strong> is a global IT service and training provider committed to providing applied, an industry-oriented training since 2016 to its customers to achieve professional goals.
              </p>
            </header>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 content">
            <p className="section-paragraph">
              We have a proven track record in delivering professional, and high-quality courses in the areas of Software Quality Assurance, Cybersecurity, Business Intelligence, and IT Help Desk Support which we are able to customize to the customer-specific requirements, time frames, and financial needs.
              <br /><br />
              We are agile and always adopt the latest technology that is in high demand in the current IT world. We ensure the quality of all our training interventions by standardizing course materials, reviewing with the industry experts, and making them relevant to upcoming technologies.
              <br /><br />
              Our instructors are experts in their respective fields and experienced professionals. Our ultimate goal is to provide excellent training to the customers and prepare them for the future job market in the IT industry. Our key focuses include customer care, respect for their professional values, and necessities.
            </p>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <YoutubeEmbed embedId="2CyNbfMKzok" />
          </div>
        </div>
      </div>
    </section>

  );
}

export default About;
