import axios from "axios";

// Create an instance of axios with baseURL
const api = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_URL,
})

// Fetch all courses
export function getcourses() {
    return api.get("/app/api/course-list/").then((res) => {
        return res.data;
    });
}

// Fetch weeks for a specific course
export function getCourseWeeks(id) {
    return api.get(`/app/api/week-list/course/${id}/`).then((res) => {
        return res.data;
    });
}

// New functions for announcements
export function getAnnouncements() {
    return api.get("/app/api/homepage-list/")
        .then((response) => response.data)
        .catch((error) => {
            console.error("Error fetching announcements:", error);
            throw error;
        });
}

// Fetch images
export function getImages() {
    return api.get("/app/api/image-list/").then((res) => {
        return res.data;
    });
}



export { api };
