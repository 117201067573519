import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import { Tabs, Tab } from "react-bootstrap";
import "aos/dist/aos.css";

import "aos/dist/aos.css";

import "./BasicComputingCourse.css";
import KhondokarIslam from "./assets/KhondokarIslam.jpg";

function DataAnalysis() {
  AOS.init();
  return (
    <>
      <Navbar />

      {/* Slider start */}

      <section className="bgimage">
        <div className="container">
          <div className="row par">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center para">
              <h1>DATA ANALYSIS WITH POWER BI</h1>
              <br />
              <p>
                Prepare yourself as a professional in cutting-edge business
                analytics services and tools with Microsoft Power BI, SQL
                Server, Data Warehousing, ERD, SSIS, SSRS, SSAS, T-SQL.
              </p>
              <h3 className="mb-3">Next Batch Starts on</h3>
              <p>
                To be Announced
                <br />
                {/* To be Announced */}
              </p>
              <p>
                <a href="#price" className="btn btn-primary btn-large">
                  Course Fee »
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Slider end */}

      {/* course info start */}
      <section>
        <div id="parallax-world-of-ugg">
          <div className="container">
            <div className="section-title text-center">
              <h2>Course Overview</h2>
            </div>
            <div className="block">
              <p>
                Business intelligence (BI) is an umbrella term for technology.
                Theoretically, the use of high class software or business
                applications of the use of values to make better decisions for
                the company, Technical and Practical business Intelligence are
                tools for collecting, processing, and analyzing data. This way
                the company can evaluate the results and interpret them.
                <br></br>
                <br></br>
                BI systems enable effective risk management, advancing a
                company’s position within the marketplace, enabling
                decision-makers to recognize performance gaps, market trends, or
                new revenue opportunities more quickly.
                <br></br>
                <br></br>
                SQL (structure Query Language) prepares data to make it ready to
                consumption in the business intelligence world. So understanding
                traditional SQL is essential before investigating into business
                intelligence(BI). SQL still remains an essential tool in the
                hands of data engineers and business intelligence.
                <br></br>
                <br></br>
                Businesses without a data warehouse were messy and unstructured
                data, from which end users on the business side of the
                organization struggled to get anything useful out of it. A data
                warehouse takes all that scattered data and lays it out nice and
                tidy in a huge relational database, through a process of
                Extract-Transform-Load (ETL).
                <br></br>
                <br></br>
                This data warehouse practice involves transforming data that is
                optimized for a heavily transactional system and moving it to
                one that is more suited for analytic querying.
                <br></br>
                <br></br>
                Power BI one of the best and secure business intelligence
                software tools. Power BI transforms your company’s data into
                rich visuals for you to collect and organize so you can focus on
                what matters to you. Stay in the know, spot trends as they
                happen, and push your business further.
                <br></br>
                <br></br>
                With Power BI Service, setup automatic data refresh so everyone
                has the latest information. You can access your data and reports
                from anywhere with the power BI Mobile apps, which update
                automatically with any changes to your data.
                <br></br>
                <br></br>
              </p>
              <p className="line-break margin-top-10" />
              <p className="margin-top-10">
                Power BI highlight :<br></br>
                <ul>
                  <li>whole business on one dashboard</li>
                  <li>Create stunning interactive reports </li>
                  <li>Consistent analysis across your organization </li>
                  <li>Easily embed BI and analytics in your app</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* course info end */}

      {/* Tab start */}

      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-12">
            <section className="payment">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="tab-menu"
              >
                <Tab
                  className="tab-item tabcourse"
                  eventKey="home"
                  title="Course Information"
                >
                  <ul>
                    <li>
                      Regular Class: Saturday & Sunday, 10am – 1pm, New York
                      Time{" "}
                    </li>
                    <li>Review Class: Thursday, 9pm-10pm, New York Time</li>
                    <li>Start Date: To be Announced</li>
                    {/* <li>To be Announced</li> */}
                    <li>
                      Duration: 12 Weeks.
                      <ul>
                        <li>Regular class: 70 Hours.</li>
                        <li>Review class : 12 Hours. </li>
                      </ul>
                    </li>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="profile"
                  title="Who Can Join"
                >
                  <ul>
                    <li>
                      Currently doing bachelor’s degree or completed bachelor’s
                      or master degree.
                    </li>
                    <li>
                      A person who is enthusiastic motivated learner, with
                      Intermediate computer knowledge.
                    </li>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="contact"
                  title="Learning Outcome"
                >
                  <ul>
                    <li>
                      After complete this course, you will be able to get a high
                      paid job as a Power BI Specialist or Power BI Developer or
                      Power BI Analyst in the field of Business Intelligence.
                    </li>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="check"
                  title="Instruction Method"
                >
                  <ul>
                    <li>Instructor-led live online</li>
                  </ul>
                </Tab>
              </Tabs>
            </section>
          </div>
        </div>
      </div>

      {/* Tab end */}

      <section>
        <div class="accordion container" id="accordionExample">
          <div className="section-title text-center">
            <h2>Course Outline</h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                MODULE - 1: Review of Microsoft SQL Server and Microsoft Power
                BI
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>What is Data</li>
                  <li>What is big Data</li>
                  <li>What is Database</li>
                  <li>What is Power BI</li>
                  <li>History of Database and Power BI</li>
                  <li>Importance of Power BI and Database</li>
                  <li>Difference Branch of Database</li>
                  <li>Current Job Market. </li>
                  <li>Power BI Pro, Power BI Premium</li>
                  <li>Comparison tools in power BI</li>
                  <li>Comparison tools in SQL server and cloud computing</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                MODULE - 2: Configuring SQL Server 2014 and Power BI 2.78
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>What is Data</li>
                  <li>What is big Data</li>
                  <li>What is Database</li>
                  <li>What is Power BI</li>
                  <li>History of Database and Power BI</li>
                  <li>Importance of Power BI and Database</li>
                  <li>Difference Branch of Database</li>
                  <li>Current Job Market. </li>
                  <li>Power BI Pro, Power BI Premium</li>
                  <li>Comparison tools in power BI</li>
                  <li>Comparison tools in SQL server and cloud computing</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                MODULE - 3: Introduction to SQL and T-SQL querying.
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Write SELECT Statement</li>
                  <li>Fetching Data from the Table.</li>
                  <li>
                    Where Clause ( Operators, in , Between, Like, And, or , not)
                  </li>
                  <li>TOP function and OFFSET–FETCH functions.</li>
                  <li>Table aliases. </li>
                  <li>DISTINCT function.</li>
                  <li>Working with Unknown values.</li>
                  <li>Order by</li>
                  <li>Logical order of operation.</li>
                  <li>Exam will be taken.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                MODULE - 4: Database table relationship (ERD) and Querying
                multiple tables.
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Understand database tables relationship (ERD) process</li>
                  <li>Primary key , Foreign key, unique key</li>
                  <li>Left Join, right join, inner join</li>
                  <li>Self-join , Cross join</li>
                  <li>Union and Union All</li>
                  <li>Querying from two tables</li>
                  <li>Querying from multiple tables</li>
                  <li>SQL Server data type</li>
                  <li>Exam will be taken.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                MODULE - 5: Implementing a Data Warehouse with SQL Server
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Understand database tables relationship (ERD) process</li>
                  <li>Primary key , Foreign key, unique key</li>
                  <li>Left Join, right join, inner join</li>
                  <li>Self-join , Cross join</li>
                  <li>Union and Union All</li>
                  <li>Querying from two tables</li>
                  <li>Querying from multiple tables</li>
                  <li>SQL Server data type</li>
                  <li>Exam will be taken.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                MODULE - 6: Introduction of power BI Desktop and Power BI Pro
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>About Power BI</li>
                  <li>Helpful Resource</li>
                  <li>Interface and work-flow</li>
                  <li>Adjusting and Regional Settings</li>
                  <li>Power BI Ribbon</li>
                  <li>Types of data Connections</li>
                  <li>Query Editor and Table transformations.</li>
                  <li>Grouping and Aggregating records</li>
                  <li>Merging and Appending Queries. </li>
                  <li>Pivoting and Un-pivoting queries. </li>
                  <li>Power BI Data type</li>
                  <li>Data source setting</li>
                  <li>Appending Queries and Files. </li>
                  <li>Understanding hierarchies in Power BI</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                MODULE - 7: Connecting and Modifying data with power BI
              </button>
            </h2>
            <div
              id="collapseSeven"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Working with Text Tools</li>
                  <li>Working with Numerical values.</li>
                  <li>Working with Date and Times</li>
                  <li>Index and conditional columns</li>
                  <li>Edit rows and columns</li>
                  <li>Splitting columns</li>
                  <li>Duplicating columns</li>
                  <li>Improving performance</li>
                  <li>Understanding the Mobile app</li>
                  <li>Exam will be taken</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                MODULE - 8: Data model and relationship in Power BI
              </button>
            </h2>
            <div
              id="collapseEight"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>What is Data Model</li>
                  <li>Understanding Dimension table</li>
                  <li>Fact table ( Data table)</li>
                  <li>Table relationship using Snowflake Schema</li>
                  <li>Table relationship using Star schema</li>
                  <li>Understanding Cardinality</li>
                  <li>Implementing two way filters in Power BI</li>
                  <li>Importance of Filter Flow</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                MODULE - 9: Introduction Data Analysis expression language (DAX)
              </button>
            </h2>
            <div
              id="collapseNine"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingNine"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Introduction DAX</li>
                  <li>Syntax of DAX</li>
                  <li>Naming in DAX</li>
                  <li>Data types in power BI Model</li>
                  <li>Logical Operator and Variable</li>
                  <li>When to use DAX or M</li>
                  <li>Calculated column ( row by row)</li>
                  <li>Measure ( Single Output)</li>
                  <li>Calculated table (Derived table)</li>
                  <li>Time Intelligence Functions</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                MODULE - 10: Implementing DAX functions and Advantage
              </button>
            </h2>
            <div
              id="collapseTen"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingTen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Dynamic DAX Parameter</li>
                  <li>DAX Function</li>
                  <li>Other type of Parameters</li>
                  <li>Parameters table</li>
                  <li>SWITCH</li>
                  <li>Related Functions</li>
                  <li>Related Table</li>
                  <li>Row and Filter Context</li>
                  <li>Calculated Function</li>
                  <li>Conditional SUM</li>
                  <li>Aggregation and Iterator Functions.</li>
                  <li>Implicit and Explicit Measure</li>
                  <li>Iterator Function</li>
                  <li>Difference between SUM and SUMX</li>
                  <li>Filter Function and ALL</li>
                  <li>ALLExcept</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEleven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                MODULE – 11: Designing and plotting Data Visualization
              </button>
            </h2>
            <div
              id="collapseEleven"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingEleven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Report View</li>
                  <li>Inserting Basic Charts</li>
                  <li>Report formatting</li>
                  <li>Conditional Formatting</li>
                  <li>Filtering Options</li>
                  <li>Filtering with Date Slicers</li>
                  <li>Matrix Visuals</li>
                  <li>Key Metrics and KPI </li>
                  <li>Different Trend Lines</li>
                  <li>Drill through Filters</li>
                  <li>Tree view and Bookmarks</li>
                  <li>Gauge and viewing roles</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwelve">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="false"
                aria-controls="collapseTwelve"
              >
                MODULE - 12: Gateway and Power BI Service
              </button>
            </h2>
            <div
              id="collapseTwelve"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingTwelve"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Power BI Service. What is Gateway</li>
                  <li>Installation and Configuration of Gateway</li>
                  <li>Live connection</li>
                  <li>Power BI Integration with different tools</li>
                  <li>Row level Security and Manager level access</li>
                  <li>Dynamic row level Security</li>
                  <li>Basic Dashboard and Report Sharing</li>
                  <li>Publish to Web</li>
                  <li>Workspace in Power BI.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThirteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirteen"
                aria-expanded="false"
                aria-controls="collapseThirteen"
              >
                MODULE - 13: Real life project implementation
              </button>
            </h2>
            <div
              id="collapseThirteen"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingThirteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Design and Implement End to End Project.</li>
                  <li>Exam and review</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFourteen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFourteen"
                aria-expanded="false"
                aria-controls="collapseFourteen"
              >
                MODULE - 14: Job Guidance and Resume Preparation
              </button>
            </h2>
            <div
              id="collapseFourteen"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingFourteen"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Resume Preparation</li>
                  <li>Job Interview Guideline</li>
                  <li>Mock interview</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <a
                class="face-button"
                href="/files/SQA.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="face-primary">
                  <span class="icon fa fa-download"></span>
                  Course Details
                </div>

                <div class="face-secondary">
                  <span class="icon fa fa-hdd-o"></span>
                  Size: 3.5 MB
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Course Instructor</h2>
          </div>

          <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
              <img
                src={KhondokarIslam}
                className="img-fluid about-image"
                alt=""
              />
            </div>

            <div className="col-md-7 pt-5" data-aos="fade-left">
              <h1 className="name">Khondaker Islam</h1>
              {/* <h3 className="position">Mentor</h3> */}
              <p className="fst-italic">
                Khondaker R Islam is the data specialist in the field of
                Business Intelligence. With over 18 years of extensive U.S. and
                Canadian experience in the field of IT and Science, He has
                conducted R & D and database development work for companies such
                as California Innovations, Brookfield (BGRS), SallieMae, Pfizer
                Inc, Apotex Inc, Purdue Pharma, Pharmacopeia Inc. and W R Grace
                & Co . He has MS degree from Howard University in Washington DC
                and also advance database diploma with Oracle and Microsoft
                certification from CBC in Canada. He is currently working as a
                Instructors in the field of Business Intelligence.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Price start */}

      <section id="price" className="pricing">
        <div className="container">
          <div className="section-title">
            <h2>Course Fee</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-md-4">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    Price
                  </h5>
                  <hr />
                  {/* <h6 className="card-price text-center">
                    $3000
                    <span className="period">One Time</span>
                  </h6>
                  <hr /> */}
                  <h5 className="card-title text-muted text-uppercase text-center">
                    <a style={{ textDecoration: "none" }} href="/contact-us">
                      Call Us
                    </a>{" "}
                    For Course Fees
                  </h5>
                  <hr />

                  {/* <h5 className="card-title text-muted text-uppercase text-center">
                    DISCOUNT
                  </h5> */}
                  {/* <h6 className="card-price text-center">
                    $2500
                    <span className="period">One Time</span>
                  </h6> */}
                  {/* <hr /> */}
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Lectures & Exams
                      {/* <strong>Lectures & Exams</strong> */}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      MCQ Quizes
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Practical
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Question Answering
                    </li>
                  </ul>
                  <div className="d-grid">
                    <a
                      href="/registration"
                      className="btn btn-primary text-uppercase"
                    >
                      Enroll
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Price end */}

      {/* table start */}
      <section>
        <div class="container">
          <div className="section-title">
            <h2>Course Schedule</h2>
          </div>
          {/* <p>
            Regular Class:<br></br>
            Weekend Batch: Saturday & Sunday, 10 AM – 12 PM, New York Time
            <br></br>
            Review Class: Thursday, 9 PM-10 PM, New York Time
          </p> */}
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Time</th>
                <th scope="col">Date (Weekend Batch)</th>
                <th scope="col">Modules</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <th scope="row" className=""></th> */}

                <td className="text-center" colspan="4">
                  To be Announced
                </td>
              </tr>
              {/* <tr>
                <th scope="row"></th>
                <td>Week 2 (6 Hours)</td>
                <td>
                  09/25/2021 (Sat)<br></br>
                  09/26/2021 (Sun)
                </td>
                <td>
                  Configuring SQL Server 2014 and Power BI 2.78<br></br>
                  Introduction to SQL and T-SQL querying.
                </td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td>Week 3 (6 Hours)</td>
                <td>
                  10/02/2021 (Sat)<br></br>
                  10/03/2021 (Sun)
                </td>
                <td>
                  Introduction to SQL and T-SQL querying.
                  <br></br>
                  Database table relationship (ERD) and Querying multiple
                  tables.
                </td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td>Week 4 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Database table relationship (ERD) and Querying multiple
                  tables.
                  <br></br>
                  Implementing a Data Warehouse with SQL Server
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 5 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Implementing a Data Warehouse with SQL Server
                  <br></br>
                  Introduction of power BI Desktop and Power BI Pro
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 6 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Connecting and Modifying data with power BI.
                  <br></br>
                  Data model and relationship in Power BI
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 7 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Data model and relationship in Power BI<br></br>
                  Introduction Data Analysis expression language (DAX)
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 8 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Introduction Data Analysis expression language (DAX)<br></br>
                  Implementing DAX functions and Advantage
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 9 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Implementing DAX functions and Advantage<br></br>
                  Designing and plotting Data Visualization
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 10 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Designing and plotting Data Visualization<br></br>
                  Gateway and Power BI Service
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 11 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>
                  Real life project implementation<br></br>
                  Job Guidance and Resume Preparation
                </td>
              </tr>

              <tr>
                <th scope="row"></th>
                <td>Week 12 (6 Hours)</td>
                <td>
                  10/09/2021 (Sat)<br></br>
                  10/10/2021 (Sun)
                </td>
                <td>Job Guidance and Resume Preparation</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </section>

      {/* table end */}

      {/* tools start */}

      <section>
        <div className="container">
          <div className="section-title">
            <h2>Tools</h2>
          </div>
          <div className="row my-4">
            <div className="col-md-6">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-windows mt-3"></i>
                  </h2>
                  <h5 className="card-title">
                    MICROSOFT SQL SERVER– 2014, 2016, 2019
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-windows mt-3"></i>
                  </h2>
                  <h5 className="card-title">MICROSOFT BI DESKTOP – 2.78</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* tools end */}
      <Footer />
    </>
  );
}

export default DataAnalysis;
