import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Readmore.css";
import Thikananews from "./assets/Thikananews.jpg";

function Readmore() {
  AOS.init();
  return (
    <>
      <Navbar />
      <section id="hero" className="newspage">
        <div className="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title text-center" data-aos="fade-up">
                <h2>NEWS </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg order-2 order-lg-1 hero-img"
              data-aos="fade-down"
            >
              <img src={Thikananews} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Readmore;
