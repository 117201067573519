import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NewsPage.css";
import shoptahante from "./assets/shoptahante.jpg";
import ProthomAlo from "./assets/ProthomAloNew.jpg";
import Muktochinta from "./assets/Muktochinta.jpg";
import cyber from "./assets/NewCyber.jpg";

function Media() {
  AOS.init();
  return (
    <>
      <Navbar />

      <section id="hero" className="newspage">
        <div className="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title text-center" data-aos="fade-up">
                <h2>MEDIA</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-6 order-2 order-lg-1 hero-img"
              data-aos="fade-left"
            >
              <img src={cyber} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-lg-0 order-1 order-lg-2 d-flex flex-column justify-content-center"
              data-aos="fade-up"
            >
              <div className="news-left">
                <h1>Media</h1>
                <p>June 16, 2022 </p>

                <h3>
                  Advertisement published in various newspapers.
                  {/* <a
                    href="https://northamerica.prothomalo.com/"
                    className="news-link"
                  >
                    Prothom Alo.
                  </a> */}
                </h3>
                <a
                  href="/files/cybersecuritynews.pdf"
                  target="_blank"
                  className="btn-get-started scrollto"
                >
                  View
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="hero" className="newspage">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-2 order-lg-1 hero-img"
              data-aos="fade-left"
            >
              <img src={shoptahante} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-lg-0 order-1 order-lg-2 d-flex flex-column justify-content-center"
              data-aos="fade-up"
            >
              <div className="news-left">
                <h1>Media</h1>
                <p>May 19, 2022</p>

                <h5>
                  দেখুন প্রতি শুক্রবারের অনুষ্ঠান QA TEK Solutions নিবেদিত
                  “সপ্তাহান্তে”, নিউ ইয়র্ক সময় রাত ৯ টায়।
                  <a href="https://fb.watch/c_LmFVpq_W/" className="news-link">
                    Latest Episode
                  </a>
                </h5>
                <a
                  href="/files/ThikanaNews.pdf"
                  target="_blank"
                  className="btn-get-started scrollto"
                >
                  View
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="hero" className="newspage">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-2 order-lg-1 hero-img"
              data-aos="fade-left"
            >
              <img src={ProthomAlo} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-lg-0 order-1 order-lg-2 d-flex flex-column justify-content-center"
              data-aos="fade-up"
            >
              <div className="news-left">
                <h1>Media</h1>
                <p>January 01, 2022 </p>

                <h3>
                  Advertisement published in
                  <a
                    href="https://northamerica.prothomalo.com/"
                    className="news-link"
                  >
                    Prothom Alo.
                  </a>
                </h3>
                <a
                  href="/files/ProthomAlo.pdf"
                  target="_blank"
                  className="btn-get-started scrollto"
                >
                  View
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="hero" className="newspage">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-2 order-lg-1 hero-img"
              data-aos="fade-left"
            >
              <img src={Muktochinta} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-lg-0 order-1 order-lg-2 d-flex flex-column justify-content-center"
              data-aos="fade-up"
            >
              <div className="news-left">
                <h1>Media</h1>
                <p>January 01, 2022 </p>

                <h3>
                  Advertisement published in
                  <a href="http://www.emuktochinta.com/" className="news-link">
                    Muktochinta.
                  </a>
                </h3>
                <a
                  href="/files/Muktochinta.pdf"
                  target="_blank"
                  className="btn-get-started scrollto"
                >
                  View
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Media;
