import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "./CoursesPage.css";
import course01 from "./assets/Accessibility Testing_02.png";
import course02 from "./assets/softwareimg.png";
import course03 from "./assets/powerimg.png";
import course04 from "./assets/python-098ad344.png";
import certificate from "./assets/certificateimg.jpg";
import cyber from "./assets/cyber.png";
import { useEffect, useState } from "react";
import { getcourses } from "./local_api/ApiService";

function CoursePage() {

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getcourses()
      .then((res) => {
        const sortedCourses = res.sort((a, b) => a.id - b.id);
        setCourses(sortedCourses);
      })
  }, []);

  // Function to get the image for each course
  const getImageForCourse = (courseId) => {
    const imageMapping = {
      4: course01,
      5: course02,
      6: cyber,
      8: course04,
    };

    return imageMapping[courseId] || null;
  };

  // Function to generate the "Details" button link
  const getDetailsLinkForCourse = (courseId) => {
    const linkMapping = {
      4: "/accessibility-testing",
      5: "/software-testing-with-selenium",
      6: "/cyber-security-for-professionals",
      8: "/data-analysis-with-python",
    };

    return linkMapping[courseId] || null;
  };

  AOS.init();
  return (
    <>
      <Navbar />
      {/* <section id="team" className="section team section-bg coursespage">
        <div className="container">
          <div className="section-title text-center">
            <h2>Our Courses</h2>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay={100}>
            <div className="col-lg-4 col-md-4 d-flex align-items-stretch">
              <div className="member">
                <img src={course01} alt="" />
                <h4>Accessibility Testing</h4>
                <br></br>
                <p className="">
                  Become an expert in Accessibility Testing by practical
                  learning of WCAG principles and guidelines and conduct testing
                  using various screen readers and tools.
                </p>
                <br></br>
                <div className="social d-flex justify-content-around align-items-center">
                  <span>
                    <i className="fas fa-clock icon" />
                    10 Weeks.
                  </span>
                </div>
                <div className="detail-button mt-3">
                  <a
                    className="btn btn-primary"
                    href="/accessibility-testing"
                    role="button"
                  >
                    Details
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 d-flex align-items-stretch">
              <div className="member">
                <img src={course02} alt="" />
                <h4>Software Testing with Selenium</h4>
                <br></br>
                <p className="">
                  Become an expert in Software Quality Assurance by practical
                  learning of manual testing with JIRA, database testing with
                  SQL, REST API testing with Postman and automation testing with
                  Selenium &amp; Java.
                </p>
                <br></br>
                <div className="social d-flex justify-content-around">
                  <span>
                    <i className="fas fa-clock icon" />
                    12 Weeks
                  </span>

                </div>
                <div className="detail-button mt-3">
                  <a
                    className="btn btn-primary"
                    href="/software-testing-with-selenium"
                    role="button"
                  >
                    Details
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 d-flex align-items-stretch">
              <div className="member">
                <img src={cyber} alt="" />
                <h4>
                  Cybersecurity for Professionals | Comprehensive CompTIA
                  Security+ Certification (SY0-601)
                </h4>

                <p className="mt-3">
                  Become a cybersecurity professional and CompTIA Security+
                  certified by hands-on learning of IT security, networking,
                  cyber threats, attacks, vulnerabilities using Windows, Linux
                  operating systems and other effective applications to pursue a
                  skilled and successful career.
                </p>
                <div className="social d-flex justify-content-around align-items-center mt-3">
                  <span>
                    <i className="fas fa-clock icon" />
                    12 Weeks
                  </span>

                </div>
                <div className="detail-button mt-3">
                  <a
                    className="btn btn-primary"
                    href="/cyber-security-for-professionals"
                    role="button"
                  >
                    Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


      <section id="courses" className="section team section-bg courses">
        <div className="container">
          <div className="section-title text-center">
            <h2>Our Courses</h2>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay={100}>
            {/* {courses.map((course) => (
              <div key={course.id} className="col-lg-4 d-flex align-items-stretch">
                <div className="member">
                  <img src={getImageForCourse(course.id)} alt={course.course_name} />
                  <h4>{course.course_name}</h4>
                  <br />
                  <p className="testing">
                    {course.course_description}
                  </p>
                  <br />

                  <div className="social02 social d-flex justify-content-around">
                    <span>
                      <i className="fas fa-clock icon" />
                      {course?.course_duration}
                    </span>
                  </div>
                  <div className="detail-button">
                    <a
                      className="btn btn-primary"
                      href={getDetailsLinkForCourse(course.id)}
                      role="button"
                    >
                      Details
                    </a>
                  </div>
                </div>
              </div>
            ))} */}



            {/* after hide cyber course */}
            {courses.map((course) => {
              if (course.id !== 6) {
                return (
                  <div key={course.id} className="col-lg-4 d-flex align-items-stretch">
                    <div className="member">
                      <img src={getImageForCourse(course.id)} alt={course.course_name} />
                      <div style={{ width: '100%', height: '70px' }}>
                        <h4>{course.course_name}</h4>
                      </div>

                      <div style={{ display: 'inline-block', width: '100%', height: '170px' }}>
                        <p className="testing overflow-hidden">
                          {course.course_description && course.course_description.length > 200
                            ? `${course.course_description.slice(0, 215)}...`
                            : course.course_description}
                        </p>
                      </div>

                      <br />

                      <div>
                        <div className="social02 social d-flex justify-content-around">
                          <span>
                            <i className="fas fa-clock icon" />
                            {course?.course_duration}
                          </span>
                        </div>
                        <div className="detail-button">
                          <a
                            className="btn btn-primary"
                            href={getDetailsLinkForCourse(course.id)}
                            role="button"
                          >
                            Details
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                );
              }
              return null;
            })}





          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default CoursePage;
