import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NewsPage.css";
import Thikananews from "./assets/Thikananews.jpg";
import cyber from "./assets/cyber1.png";
import accessibility from "./assets/Accessibility Testing_02.png";
import software from "./assets/software.png";
import { useState } from "react";
import { useEffect } from "react";
import { api, getAnnouncements } from "./local_api/ApiService";
import { format } from "date-fns";

function Newspage() {
  AOS.init();

  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    getAnnouncements()
      .then((data) => {
        setAnnouncements(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error in YourComponent:", error);
      });
  }, []);


  return (
    <>
      <Navbar />
      <section id="hero" className="newspage">
        <div className="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title text-center" data-aos="fade-up">
                <h2> ANNOUNCEMENTS </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {announcements.map((announcement) => (
        <section key={announcement.id} id="about" className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in">
                <img src={api.defaults.baseURL + announcement.image} className="img-fluid" alt="" style={{ width: 1200, height: 350 }} />
              </div>
              <div
                className="col-lg-6 d-flex flex-column justify-contents-center"
                data-aos="fade-left"
              >
                <div className="content pt-4 pt-lg-0">
                  <h3>{announcement.title}</h3>
                  <p className="grid-date-post">{format(new Date(announcement.date), "MMMM d, YYY")}</p>
                  <p className="announcement-detail">
                    {announcement.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
      <Footer />
    </>
  );
}

export default Newspage;
