import Topbar from './Topbar';
import Navbar from './Navbar';
import Footer from './Footer';
import AOS from 'aos';

import 'aos/dist/aos.css';

import './Career.css';

function Career() {
  AOS.init();
  return (
    <>
      <Navbar />
      <section className="career">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto mb-4">
              <div className="about-us-title section-title text-center ">
                <span>Career</span>
                <h2 className="top-c-sep careerheader">CAREER</h2>
                <p className="career-paragraph">Grow Your Career With Us</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="career-search mb-60">
                <div className="filter-result">
                  <p className="mb-30 ff-montserrat">Total Job Openings : 01</p>
                  {/* <div
                    className="job-box d-md-flex align-items-center justify-content-between mb-30"
                    data-aos="fade-up"
                  >
                    <div className="job-left my-4 d-md-flex align-items-center flex-wrap">
                      <div className="img-holder me-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
                        WD
                      </div>
                      <div className="job-content">
                        <h5 className="text-center text-md-start">
                          Full Stack Web Developer
                        </h5>
                        <ul className="d-md-flex flex-wrap text-capitalize ff-open-sans list">
                          <li className="me-md-4">
                            <i class="fas fa-clock me-2"></i> Full Time
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="detail-button ">
                      <a
                        className="btn btn-primary"
                        href="/files/Full-Stack-Web-Developer.pdf"
                        role="button"
                        target="_blank"
                      >
                        Details
                      </a>
                    </div>
                  </div> */}
                  <div
                    className="job-box d-md-flex align-items-center justify-content-between mb-30"
                    data-aos="fade-up"
                  >
                    <div className="job-left my-4 d-md-flex align-items-center flex-wrap">
                      <div className="img-holder me-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
                        SM
                      </div>
                      <div className="job-content">
                        <h5 className="text-center text-md-start">
                          Business Support Manager
                        </h5>
                        <ul className="d-md-flex flex-wrap text-capitalize ff-open-sans list">
                          {/* <li className="me-md-4">
                            <i class="fas fa-map-marker-alt me-2"></i> Los
                            Angeles
                          </li> */}
                          {/* <li className="me-md-4">
                            <i class="fas fa-dollar-sign me-2"></i> 2500-3500
                          </li> */}
                          <li className="me-md-4">
                            <i class="fas fa-clock me-2"></i> Part Time
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="detail-button ">
                      <a
                        className="btn btn-primary"
                        href="/files/job_circular_business_support_manager.pdf"
                        role="button"
                        target="_blank"
                      >
                        Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* START Pagination */}
              {/* <nav aria-label="Page navigation">
                <ul className="pagination pagination-reset justify-content-center">
                  <li className="page-item disabled">
                    <a
                      className="page-link"
                      href="#"
                      tabIndex={-1}
                      aria-disabled="true"
                    >
                      <i className="zmdi zmdi-long-arrow-left" />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item d-none d-md-inline-block">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item d-none d-md-inline-block">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      ...
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      8
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="zmdi zmdi-long-arrow-right" />
                    </a>
                  </li>
                </ul>
              </nav> */}
              {/* END Pagination */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Career;
