import Navbar from "./Navbar";
import Footer from "./Footer";
import "./Instructor.css";
import RifatHalim from "./assets/Rifat-Halim.JPG";
import FaridUddin from "./assets/Farid-Uddin.jpeg";
import ShajibKhadem from "./assets/Shajib-khadem.jpg";
import nibir from "./assets/nibir.png";
import abdulla from "./assets/abdulla.jpg";

import logo from "./assets/logo.png";

function Instructor() {
  return (
    <>
      <Navbar about="active" />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="breadcrumb-hero">
          <div class="container">
            <div class="breadcrumb-hero">
              <a href="#">
                <img src={logo} className="hero-image" alt="" />
              </a>
              <h3>QA Tek Solutions Instructors</h3>
              <p>Door Steps to IT World</p>
            </div>
          </div>
        </div>
      </section>

      <div id="team" className="our-team-area area-padding">
        <div className="container ">
          <div className="section-title instructor-title text-center">
            <h2>Our Instructors</h2>
          </div>

          <section id="instructor" class="instructor">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 mt-4 mt-lg-0">
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={RifatHalim} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Rifat M Halim</h4>
                      <span>Lead Instructor</span>
                      <p>
                        Mr. Rifat is the lead instructor in QA Tek Solutions
                        Inc. He is a Sr. Software Engineer in Test in the
                        profession. He has been in IT Industries for many years.
                        He is an expert Automation Engineer and working in
                        different domains over the years. His style of teaching
                        automation topics is significant and up to the demands
                        of the current job market.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="member d-flex align-items-start member-2">
                    <div class="pic">
                      <img src={FaridUddin} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Mohammad Uddin</h4>
                      <span>Lead Instructor</span>
                      <p>
                        Mr. Uddin is the lead instructor in QA Tek Solutions
                        Inc. He is a SQA Engineer with Lowes Company, one of the
                        largest retail companies in the US. He has been in IT
                        Industries for quite a bit of time. He has been an
                        expert SQA Engineer and working in different domains.
                        His style of teaching software testing is simple and
                        deep.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mt-4">
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={ShajibKhadem} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Shajib Khadem</h4>
                      <span>Lead Instructor</span>
                      <p>
                        Mr. Shajib Khadem is a Lead Instructor in QA Tek
                        Solutions Inc. He is currently working in Verizon as a
                        Lead Consultant. He has vast experience in
                        telecommunications, automation, IT solutions, customer
                        service & assurance, process improvements, change
                        management, professional training development from the
                        USA, Australia, and Netherland.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mt-4">
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={nibir} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Nibir Hasan</h4>
                      <span>Assistant Instructor</span>
                      <p>
                        Mr. Nibir is the assistant instructor in QA Tek
                        Solutions Inc. He is a Software Engineer in the
                        profession. He is our alumni. He has successfully
                        completed his course from QA Tek Solutions Inc and got a
                        job. He is an expert Test Engineer who comprises
                        knowledge of API testing and Automation testing. He
                        teaches API testing in QA Tek Solutions Inc.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mt-4">
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={abdulla} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Abdulla Syed</h4>
                      <span>Assistant Instructor</span>
                      <p>
                        Mr. Abdulla is the assistant instructor in QA Tek
                        Solutions Inc. He is a Teacher in the profession as
                        well. He is our alumni. He has successfully completed
                        his course from QA Tek Solutions Inc. He is an expert
                        Test Engineer who comprises knowledge of Database
                        testing and Automation testing. He teaches Database
                        Testing With SQL in QA Tek Solutions Inc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Instructor;
