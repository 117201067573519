import React from 'react';

const Acknowledgment = () => {
    return (
        <>
            <section id="" className="privacy">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2 className="text-center"> ACKNOWLEDGEMENTS FOR ENROLLING IN A COURSE </h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 content">
                                    <ol>
                                        <li>The Student needs to enroll in QA Tek Solutions Inc. (QA TEK) Learning Management System (LMS) to access class contents, videos, and other materials required. The course materials are not downloadable. </li>
                                        <br />
                                        <li>Sharing LMS Login credentials and the course materials of QA TEK is strictly prohibited.</li>
                                        <br />
                                        <li>If the LMS is unavailable for any reason, QA TEK will manage to provide the class contents by other means.</li>
                                        <br />
                                        <li>
                                            Total course fee for <span style={{ fontWeight: 'bold' }}>Software Testing with Selenium:</span> $2500
                                        </li>
                                        <div>
                                            <ol type="a" className="mt-2">
                                                <li>
                                                    If a student wants a monthly installment option, here is the Monthly Installment Breakdown:
                                                    <ul>
                                                        <li>During the enrollment, Students need to pay $100</li>
                                                        <li>$800 within Week 4</li>
                                                        <li>$800 within Week 8</li>
                                                        <li>$800 within Week 12</li>
                                                    </ul>
                                                </li>
                                                <li className="my-2">NO Refund will be given after the first four (4) weeks regardless the student attends or not during this time. </li>
                                                <li>If the student wants to enroll right after the trial class, then there will be NO refund after the enrollment as s/he used up the enrollment period as a trial class. </li>
                                            </ol>
                                        </div>
                                        <br />
                                        <li>Total course fee for <span style={{ fontWeight: 'bold' }}>Accessibility Testing:</span> $2200.</li>
                                        <div>
                                            <ol type="a" className="mt-2">
                                                <li>
                                                    If a student wants a monthly installment option, here is the Monthly Installment Breakdown:
                                                    <ul>
                                                        <li>During the enrollment, Students need to pay $100</li>
                                                        <li>$700 within Week 3</li>
                                                        <li>$700 within Week 6</li>
                                                        <li>$700 within Week 9</li>
                                                    </ul>
                                                </li>
                                                <li className="my-2">NO Refund will be given after the first two (2) weeks regardless of whether the student attends or not during this time. </li>
                                                <li>If the student wants to enroll right after the trial class, then there will be NO refund after the enrollment as s/he used up the enrollment period as a trial class.</li>
                                            </ol>
                                        </div>
                                        <br />
                                        <li>Total course fees for <span style={{ fontWeight: 'bold' }}>Data Analysis with Python:</span> $2500</li>
                                        <div>
                                            <ol type="a" className="mt-2">
                                                <li>
                                                    If a student wants a monthly installment option, here is the Monthly Installment Breakdown:
                                                    <ul>
                                                        <li>During the enrollment, Students need to pay $100</li>
                                                        <li>$800 within Week 4</li>
                                                        <li>$800 within Week 8</li>
                                                        <li>$800 within Week 12</li>
                                                    </ul>
                                                </li>
                                                <li className="my-2">NO Refund will be given after the first two (2) weeks regardless of whether the student attends or not during this time. </li>
                                                <li>
                                                    If the student wants to enroll right after the trial class, then there will be NO refund after the enrollment as s/he used up the enrollment period as a trial class.
                                                </li>
                                            </ol>
                                        </div>
                                        <br />
                                        <li>The Final amount of the course may be changed due to, but not limited to, special sales, sales promo, and referral bonus offered by QA TEK. Only QA TEK can decide on any discount that needs to be finalized before starting the course. </li>
                                        <br />
                                        <li>A Student must be committed to attending weekly classes, submitting homework, and giving presentations from time to time in the class.</li>
                                        <br />
                                        <li>Course outlines and materials are subject to change by the instructors of QA TEK. The course materials will be provided through LMS after the class, including class videos, PowerPoint slides, exercises, etc.</li>
                                        <br />
                                        <li>If any class is canceled for holiday or emergency reasons, it will be notified earlier than the scheduled class date and time.</li>
                                        <br />
                                        <li>If any student misses class continuously for 2 weeks without notification, the QA TEK management has the right to take disciplinary action.</li>
                                        <br />
                                        <li>After the course completion, the student will be examined with an assessment, and the assessment is mandatory for all students who are willing to get job assistance.</li>
                                        <br />
                                        <li>The student’s confidential information will be used only to collect course fees. In any case, this information will not be shared or disclosed outside of QA TEK.
                                        </li>
                                        <br />
                                        <li>The course fee is non-refundable.</li>
                                        <br />
                                        <li> A referral bonus of up to $100 will be given to the person who introduces an enrolled student in any QA TEK course; it will be decided by the QA TEK management.</li>
                                        <br />
                                        <li>If any student is required to use any paid software license such as Microsoft Office, he/she needs to purchase it on his/her own. The course fees do not include any paid software license. Students are encouraged to discuss with the instructor before purchasing any relevant software/tools.</li>
                                        <br />
                                        <li>A Student needs to provide a form of picture identification when the signed copy of this acknowledgment form is sent to QA TEK.</li>
                                        <br />
                                        <li>QA TEK is the sole owner of any picture or video taken at QA TEK premises. The Students or any other users may seek prior permission to take any pictures or videos or any other multimedia media. </li>
                                        <br />
                                        <li>
                                            QA TEK gets automatic permission to take pictures of any objects or persons who happen to be on QA TEK premises.
                                        </li>
                                        <br />
                                        <li>
                                            QA TEK reserves the full right to take disciplinary action or terminate the student with a prior warning if s/he violates the company’s rules.
                                        </li>
                                        <br />
                                        <li>
                                            After finishing the course successfully, the student needs to sign a separate acknowledgment form for the Job assistance program.
                                        </li>
                                        <br />
                                        <li>
                                            Any terms and conditions are subject to change by the management of QA TEK.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Acknowledgment;

