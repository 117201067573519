import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NewsPage.css";
import Thikananews from "./assets/Thikananews.jpg";
import cyber from "./assets/cyber1.png";
import power from "./assets/power.png";
import software from "./assets/software.png";

function Events() {
  AOS.init();
  return (
    <>
      <Navbar />
      <section id="hero" className="newspage">
        <div className="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title text-center" data-aos="fade-up">
                <h2> EVENTS </h2>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div
              className="col-lg-6 order-2 order-lg-1 hero-img"
              data-aos="fade-left"
            >
              <img src={Thikananews} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-5 pt-lg-0 order-1 order-lg-2 d-flex flex-column justify-content-center"
              data-aos="fade-up"
            >
              <div className="news-left">
                <h1>NEWS</h1>
                <p>January 01, 2022 </p>

                <h3>
                  Advertisement published in
                  <a href="https://www.thikana.us" className="news-link">
                    https://www.thikana.us.
                  </a>
                </h3>
                <a href="/readmore" className="btn-get-started scrollto">
                  Read more
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section id="about" className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6" data-aos="zoom-in">
              <img src={cyber} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 d-flex flex-column justify-contents-center"
              data-aos="fade-left"
            >
              <div className="content pt-4 pt-lg-0">
                <h3>ANNOUNCEMENT</h3>
                <p className="grid-date-post">August 06, 2022</p>
                <p className="announcement-detail">
                  <a href="/registration">
                    Next Batch Starts on August 06, 2022 for Cyber Security for
                    Professionals. Please Register.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6" data-aos="zoom-in">
              <img src={cyber} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 d-flex flex-column justify-contents-center"
              data-aos="fade-left"
            >
              <div className="content pt-4 pt-lg-0">
                <h3>ANNOUNCEMENT</h3>
                <p className="grid-date-post">July 16, 2022</p>
                <a href="trial-class">
                  <p className="announcement-detail">
                    Join Our Free Information Session on Cyber Security for
                    Professinals on July 16, 2022. Please Register.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6" data-aos="zoom-in">
              <img src={software} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 d-flex flex-column justify-contents-center"
              data-aos="fade-left"
            >
              <div className="content pt-4 pt-lg-0">
                <h3>ANNOUNCEMENT</h3>
                <p className="grid-date-post">May 21, 2022</p>
                <p className="announcement-detail">
                  Next Batch Starts on May 21, 2022 for Software Testing
                  (Manual, SQL, Automation). Please Register.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6" data-aos="zoom-in">
              <img src={software} className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 d-flex flex-column justify-contents-center"
              data-aos="fade-left"
            >
              <div className="content pt-4 pt-lg-0">
                <h3>ANNOUNCEMENT</h3>
                <p className="grid-date-post">May 14, 2022</p>
                <p className="announcement-detail">
                  Join Our Free Information Session on May 14, 2022. Please
                  Register.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Events;
