import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Tabs, Tab } from "react-bootstrap";
import "aos/dist/aos.css";
import AOS from "aos";
import jsonData from './local_api/accessibility.json';

import "aos/dist/aos.css";
import DBeaver from "./assets/DBeaver_logo.png";
import powerBl from "./assets/powerBl.png";
import jupyter from "./assets/jupyter.png";

import "./BasicComputingCourse.css";
import ShajibKhadem from "./assets/Shajib-khadem.jpg";
import RifatHalim from "./assets/Rifat-Halim.JPG";
import FaridUddin from "./assets/Farid-Uddin.jpeg";
import { useEffect, useState } from "react";
import { getCourseWeeks, getcourses } from "./local_api/ApiService";

function DataAnalysisWithPython() {
    AOS.init();

    // data fetching function for localapi
    const id = 8;

    const [courseDetails, setCourseDetails] = useState(null);
    const [courseWeeks, setCourseWeeks] = useState([]);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const courses = await getcourses();
                const course = courses.find((course) => course.id === parseInt(id));

                if (course) {
                    setCourseDetails(course);

                    // Fetch weeks data for the course from ApiService
                    const weeks = await getCourseWeeks(id);
                    setCourseWeeks(weeks);
                } else {
                    setError("Course not found");
                }
            } catch (error) {
                setError("Error fetching data");
            }
        };

        fetchData();
    }, []);

    console.log('courses', courseDetails);
    console.log('weeks', courseWeeks);

    const sortWeeks = (weeks) => {
        return weeks.slice().sort((a, b) => {
            const weekA = a.week.toLowerCase();
            const weekB = b.week.toLowerCase();
            return weekA.localeCompare(weekB, undefined, { numeric: true, sensitivity: 'base' });
        });
    };

    return (
        <>
            <Navbar />

            {/* Slider start */}

            <section className="bgimage">
                <div className="container">
                    <div className="row par">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center para">
                            <h1>{courseDetails?.course_name}</h1>
                            <br />
                            <p>
                                {courseDetails?.course_description}
                            </p>
                            <h3 className="mb-3">Upcoming Batch Details</h3>
                            <p>
                                Main Course: {courseDetails?.start_date}
                                <br />
                                Trial Class: {courseDetails?.trial_date}
                            </p>
                            <p>
                                <a href="#price" className="btn btn-primary btn-large">
                                    Course Fee »
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Slider end */}

            {/* course info start */}
            <section>
                <div id="parallax-world-of-ugg">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>Course Overview</h2>
                        </div>
                        <div className="block">
                            <p>
                                Python is a high-level, interpreted programming language that is widely used in data analysis. It is an open-source language that is easy to learn and has a large community of developers. Python is important to learn because it is a versatile language that can be used for a wide range of tasks, including data analysis. It is also a popular language in the field of data analysis, and learning Python can help you get a job in this field. Python is used in data analysis because it is easy to use, has a large number of libraries, and is fast.
                                <br></br>
                                <br></br>
                                Data analysis is the process of examining data to extract useful information. It involves cleaning, transforming, and modeling data to discover useful insights. Data analysis is an important field because it helps organizations make better decisions. Data analysts are in high demand, and the job prospects for data analysts are good. According to the U.S. Bureau of Labor Statistics, employment of data scientists and mathematical science occupations will grow 31% through most of this decade. Data analysts are sought-after professionals who serve in high positions in renowned tech, eCommerce, pharmaceutical, and manufacturing industries. The career path for data analyst professionals depends on their skills and interests. The most significant number of analytics job ads in the US are in New York. SQL and Python are the most sought-after techniques for data analysts. 54% of employers list Excel as an essential skill for data analytics roles. The top three degrees for data analysts are computer science, statistics, and engineering.
                                <br></br>
                                <br></br>
                                Python has many libraries and features that make it a powerful tool for data analysis. Some of the most popular libraries for data analysis in Python include NumPy, SciPy, and Pandas. NumPy is a library for scientific computing that provides support for arrays and matrices. SciPy is a library for scientific and technical computing that provides support for optimization, integration, and linear algebra. Pandas is a library for data manipulation and analysis that provides support for data structures and tools for data analysis. Python also has many features that make it a powerful tool for data analysis, including its ability to handle large datasets, its support for parallel processing, and its ability to integrate with other tools and languages.
                                <br></br>
                                <br></br>
                                SQL is a programming language that is used to manage and manipulate data in relational databases. Power BI is a data visualization tool that allows users to create interactive and dynamic visualizations of data. SQL and Power BI are important tools for data analysis because they allow users to analyze and visualize data in a way that is easy to understand. SQL is used to extract data from databases, and Power BI is used to create visualizations of that data. Together, SQL and Power BI provide a powerful toolset for data analysis that can help organizations make better decisions.
                            </p>
                            {/* <p className="line-break margin-top-10" />
              <p className="margin-top-10">
                This course will provide complete guidance on how to provide
                technical assistance and support related to IT systems, hardware
                & software solutions as well as to manage customer expectations
                with professionalism in a timely manner.
              </p> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* course info end */}

            {/* Tab start */}

            <div className="container">
                <div className="row" data-aos="fade-up">
                    <div className="col-12">
                        <section className="payment">
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="tab-menu"
                            >
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="home"
                                    title="Course Information"
                                >
                                    <ul>
                                        <li>
                                            Weekend Classes: {courseDetails?.weekend_classes} | New
                                            York Time
                                        </li>

                                        <li>
                                            Student Support Session: {courseDetails?.student_support_session} |
                                            New York Time
                                        </li>
                                        <li>Start Date: {courseDetails?.start_date} (Weekend Batch)</li>

                                        <li>
                                            Duration: {courseDetails?.course_duration}.
                                            <ul>
                                                <li>Regular Class: {courseDetails?.total_class_duration}</li>
                                                <li>Student Support Session: {courseDetails?.support_session_duration}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="profile"
                                    title="Who Can Join"
                                >
                                    <ul>
                                        <li>Bachelor’s degree from any discipline</li>
                                        <li>Proficiency in English will be a huge plus</li>
                                    </ul>
                                </Tab>
                                {/* <Tab
                  className="tab-item tabcourse"
                  eventKey="prerequisites"
                  title="Prerequisites"
                >
                  <ul>
                    <li>
                      A motivated person with a Bachelor degree in any
                      discipline who is seeking an IT professional job.
                    </li>
                  </ul>
                </Tab> */}
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="contact"
                                    title="Learning Outcome"
                                >
                                    <ul>
                                        <li>
                                            Students will be capable of performing data analysis and visualization to get the business insights any data driven company.
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab
                                    className="tab-item tabcourse"
                                    eventKey="check"
                                    title="Instruction Method"
                                >
                                    <ul>
                                        <li>Instructor led (Online only)</li>
                                    </ul>
                                </Tab>
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>

            {/* Tab end */}

            {/* Accordion start */}

            <section>
                <div class="accordion container" id="accordionExample">
                    <div className="section-title text-center">
                        <h2>Course Outline</h2>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                MODULE 1: Basic Computing
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <ul>
                                    <li>Computer fundamentals, Operating Systems, MS office products</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                MODULE 2: Introduction to Python
                            </button>
                        </h2>
                        <div
                            id="collapseTwo"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <ul>
                                    <li>Environment setup, jupyter notebook</li>
                                    <li>Math operations, data types</li>
                                    <li>Variables, list, tuples, strings</li>
                                    <li>Input Output, dictionaries and sets</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                            >
                                MODULE 3: Python control structures
                            </button>
                        </h2>
                        <div
                            id="collapseFour"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <ul>
                                    <li>Functions, modules, data structures, OOP Programming</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                            >
                                MODULE 4: Data Analysis with python
                            </button>
                        </h2>
                        <div
                            id="collapseFive"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <ul>
                                    <li>NumPy, Pandas, MatplotLib, PySpark</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSix">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSix"
                                aria-expanded="false"
                                aria-controls="collapseSix"
                            >
                                MODULE 5: Data Analysis with SQL and visualization
                            </button>
                        </h2>
                        <div
                            id="collapseSix"
                            class="accordion-collapse collapse accord"
                            aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body text-white">
                                <ul>
                                    <li>PostgreSQL, Tableau/Power BI</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSeven">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                            >
                                MODULE 6: Hands on project
                            </button>
                        </h2>
                    </div>

                    <div className="row">
                        <div className="col">
                            <a
                                class="face-button"
                                href="/files/Brochure.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div class="face-primary">
                                    <span class="icon fa fa-download"></span>
                                    Course Details
                                </div>

                                <div class="face-secondary">
                                    <span class="icon fa fa-hdd-o"></span>
                                    Size: 4.80 MB
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Accordion end */}

            {/* Price start */}

            <section id="price" className="pricing">
                <div className="container">
                    <div className="section-title">
                        <h2>Course Fee</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-md-4">
                            <div className="card mb-5 mb-lg-0">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">
                                        Price
                                    </h5>
                                    {/* <h6 className="card-price text-center">
                    $3500
                    <span className="period">One Time</span>
                  </h6> */}
                                    <hr />

                                    <h5 className="card-title text-muted text-uppercase text-center">
                                        <a style={{ textDecoration: "none" }} href="/contact-us">
                                            Call Us
                                        </a>{" "}
                                        For Course Fees
                                    </h5>
                                    {/* <h6 className="card-price text-center">
                    $3000
                    <span className="period">One Time</span>
                  </h6> */}
                                    <hr />
                                    <ul className="fa-ul">
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            Lectures & Exams
                                            {/* <strong>Lectures & Exams</strong> */}
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            MCQ Quizes
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            Practical
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <i className="fas fa-check" />
                                            </span>
                                            Question Answering
                                        </li>
                                    </ul>
                                    <div className="d-grid">
                                        <a
                                            href="/registration"
                                            className="btn btn-primary text-uppercase"
                                        >
                                            Enroll
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Price end */}

            {/* table start */}
            <section>
                <div class="container">
                    <div className="section-title">
                        <h2>Course Schedule</h2>
                    </div>
                    <p>
                        <strong>Weekend Classes: </strong> {courseDetails?.weekend_classes} |
                        New York Time
                        <br></br>
                        <strong>Student Support Session: </strong> {courseDetails?.student_support_session} |
                        New York Time
                        <br></br>
                    </p>
                    <table class="table text-center">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Time</th>
                                <th scope="col">Date (Weekend)</th>
                                {/* <th scope="col">Date (Weekday)</th> */}
                                <th scope="col">Modules</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortWeeks(courseWeeks).map((week, index) => (
                                    <tr key={week?.week}>
                                        <th scope="row"></th>
                                        <td>{week.week} ({courseDetails.class_duration})</td>
                                        <td>
                                            {week.date_01}<br></br>
                                            {week.date_02}
                                        </td>
                                        <td>{week.module}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            {/* table end */}

            {/* tools start */}

            <section>
                <div className="container">
                    <div className="section-title">
                        <h2>Tools</h2>
                    </div>
                    <div className="row my-4">

                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block mt-5">
                                    <h2>
                                        <img width={70} src={DBeaver} alt="" />
                                    </h2>
                                    <h5 className="card-title">DBeaver</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block mt-5">
                                    <h2>
                                        <img width={60} src={jupyter} alt="" />
                                    </h2>
                                    <h5 className="card-title">Jupyter</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" text-center">
                                <div className="card-block mt-5">
                                    <h2>
                                        <img width={80} src={powerBl} alt="" />
                                    </h2>
                                    <h5 className="card-title">PowerBI</h5>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/* tools end */}
            <Footer />
        </>

    );
}

export default DataAnalysisWithPython;