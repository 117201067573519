import React, { useState, useCallback, useEffect } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./Photos";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { api, getImages } from "./local_api/ApiService";

function Gallery02() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  const [images, setImages] = useState([]);


  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = () => {
    getImages()
      .then((response) => {
        setImages(response);
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });
  };

  const galleryImages = images.map((image) => ({
    src: api.defaults.baseURL + image.image,
    width: 4,
    height: 3,
  }));

  return (
    <>
      <section>
        <Navbar />
        <div className="container">
          <div className="section-title text-center gallery-title">
            <h2>GALLERY</h2>
          </div>
          <hr className="mt-2 mb-5" />
          <Gallery photos={galleryImages} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={galleryImages.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default Gallery02;
