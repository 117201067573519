import { useState, useEffect } from "react";
import UAParser from "ua-parser-js";

import "./Footer.css";
function Footer() {
  const parser = new UAParser();
  const [os, setOS] = useState("");
  const [footerClass, setFooterClass] = useState("");

  const toggleFooterMenu = (footerClassRef) => {
    if (footerClassRef === footerClass) {
      document
        .getElementById(footerClass)
        .getElementsByTagName("div")[0]
        .classList.remove("footer-dropdown");
      setFooterClass("");
    } else {
      var mainDiv = document.getElementById(footerClassRef);

      mainDiv.children[1].classList.add("footer-dropdown");

      if (footerClass) {
        document
          .getElementById(footerClass)
          .children[1].classList.remove("footer-dropdown");
      }

      setFooterClass(footerClassRef);
    }
  };
  var userAgent = window.navigator.userAgent;

  useEffect(() => {
    setOS(parser.setUA(userAgent).getResult().os.name);
    console.log(os);
  }, [parser, os, userAgent]);
  return (
    <footer className="footer-48201">
      <a
        href={
          os === "Android" || os === "iOS"
            ? "https://wa.me/13472591930?"
            : "https://web.whatsapp.com/send?phone=13472591930&text="
        }
        className="float"
        target="_blank"
        rel="noopener noreferrer"
        role="button"
      >
        <i className="fab fa-whatsapp my-float" aria-hidden="true"></i>
        <span>WhatsApp message</span>
      </a>
      <div className="container">
        <div className="row">
          <div className="col-md about-footer">
            <ul className="list-unstyled nav-links">
              <li>
                <div
                  id="accordion-about"
                  className="menu-item"
                  onClick={() => toggleFooterMenu("accordion-about")}
                >
                  <a className="title accordion-btn">ABOUT</a>
                  <div className="menu-item__sub">
                    <ul className="list-unstyled sub-menu">
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      {/* <li>
                        <a href="/career">
                          <span className="sub-transition">Career</span>
                        </a>
                      </li> */}
                      <li>
                        <a href="/team">Our Team</a>
                      </li>
                      <li>
                        <a href="/instructor">Our Instructors</a>
                      </li>
                      <li>
                        <a href="/mediaandgallery">Media & Gallery</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md registration-footer">
            <ul className="list-unstyled nav-links">
              <li>
                <div
                  id="accordion-registration"
                  className="menu-item"
                  onClick={() => toggleFooterMenu("accordion-registration")}
                >
                  <a className="title accordion-btn">REGISTRATION</a>
                  <div className="menu-item__sub">
                    <ul className="list-unstyled sub-menu">
                      <li>
                        <a href="/courses">Courses</a>
                      </li>
                      <li>
                        <a href="/registration">Registration</a>
                      </li>
                      <li>
                        <a href="/trial-class">Join Trial Class</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-md payment-footer">
            <ul className="list-unstyled nav-links">
              <li>
                <div
                  id="accordion-payment"
                  className="menu-item"
                  onClick={() => toggleFooterMenu("accordion-payment")}
                >
                  <a className="title accordion-btn">PAYMENT</a>
                  <div className="menu-item__sub">
                    <ul className="list-unstyled sub-menu">
                      <li>
                        <a href="/payment">Zelle</a>
                      </li>
                      <li>
                        <a href="/payment">Cash</a>
                      </li>
                      <li>
                        <a href="/payment">Credit card</a>
                      </li>
                      <li>
                        <a href="/payment">Check</a>
                      </li>
                      <li>
                        <a
                          href="/files/Refund Policy.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Refund policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md">
            <ul className="list-unstyled nav-links">
              <li>
                <div
                  id="accordion-lms"
                  className="menu-item"
                  onClick={() => toggleFooterMenu("accordion-lms")}
                >
                  <a className="title accordion-btn">LMS</a>
                  <div className="menu-item__sub">
                    <ul className="list-unstyled sub-menu">
                      <li>
                        <a
                          href="https://qateksolutions-lms.com/login"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Log In
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-md-4 text-md-center social-title">
            <h3>GET IN TOUCH</h3>
            <ul className="social list-unstyled">
              <li>
                <i className="fas fa-phone-alt"></i>
                NY (347) 259-1930, NY (347) 658-9284
              </li>
              {/* <li>
                <i className="fas fa-phone-alt"></i>
                VA (571) 457-0371
              </li> */}
            </ul>
            <ul className="social list-unstyled">
              <li>
                <a
                  href="https://www.facebook.com/QA-Tek-Solutions-Inc-112681010483577"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <i className="fab fa-facebook" aria-hidden="true"></i>
                  <span className="sr-only">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCiqY0yp-sjdePPg-KsmRx_A"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="YouTube"
                >
                  <i className="fab fa-youtube" aria-hidden="true"></i>
                  <span className="sr-only">YouTube</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/qa-tek-solutions-inc"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <i className="fab fa-linkedin" aria-hidden="true"></i>
                  <span className="sr-only">LinkedIn</span>
                </a>
              </li>
              <li>
                <a
                  href={
                    os === "Android" || os === "iOS"
                      ? "https://wa.me/13472591930?"
                      : "https://web.whatsapp.com/send?phone=13472591930&text="
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="WhatsApp"
                >
                  <i className="fab fa-whatsapp" aria-hidden="true"></i>
                  <span className="sr-only">WhatsApp</span>
                </a>
              </li>
            </ul>
          </div>


        </div>

        <div className="row ">
          <div className="col-12 text-center">
            <div className="copyright mt-5 pt-5">
              <div className="address">
                <p className="title-address">ADDRESS</p>
                <ul>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    118-35 Queens Blvd, Suite 400, Forest Hills, NY 11375<br></br>
                  </li>
                  {/* <li>
                    <i className="fas fa-map-marker-alt"></i>
                    89-16 175 St., CF-4, Jamaica, NY 11432 <br></br>
                  </li> */}
                </ul>
              </div>
              {/* <p>
                <small>© 2023 QA Tek Solutions Inc. All rights reserved.</small>
              </p> */}
              <p>
                <small>© {new Date().getFullYear()} QA Tek Solutions Inc. All rights reserved.</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>

  );
}

export default Footer;
