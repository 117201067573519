import { useState, useEffect } from "react";
import UAParser from "ua-parser-js";
import "./Navbar.css";
import logo from "./assets/logo.png";

function Navbar({ home, about, course, payment, lms }) {
  const parser = new UAParser();
  const [os, setOS] = useState("");
  const toggleMenu = () => {
    let nav = document.getElementById("navbar");

    nav.classList.toggle("navbar-mobile");
    let navButton = document.getElementById("nav-button");

    navButton.classList.toggle("fa-bars");
    navButton.classList.toggle("fa-times");
  };

  const toggleDropdown = (navclassname) => {
    let nav = document.getElementById("navbar");
    let courseMobile = document.getElementById("course-mobile");

    if (nav.classList.contains("navbar-mobile")) {
      // e.preventDefault();
      let navSubmenu = document.getElementById(navclassname);
      let navMobileHeight = document.getElementById("course");
      if (navclassname === "courses-submenu") {
        document
          .getElementById("about-submenu")
          .nextElementSibling.classList.remove("dropdown-active");
        navMobileHeight.classList.remove("course-dropdown-height");
      } else {
        document
          .getElementById("courses-submenu")
          .nextElementSibling.classList.remove("dropdown-active");
        navMobileHeight.classList.add("course-dropdown-height");
      }

      navSubmenu.nextElementSibling.classList.toggle("dropdown-active");
      if (navSubmenu.nextElementSibling.classList.contains("dropdown-active")) {
        if (navSubmenu.nextElementSibling.classList.contains("course-mobile")) {
          // navMobileHeight.classList.add("course-dropdown-height");
        } else {
          navMobileHeight.classList.add("mobile-height");
        }
      } else {
        navMobileHeight.classList.remove("mobile-height");
        navMobileHeight.classList.remove("course-dropdown-height");
      }
    }
  };

  var userAgent = window.navigator.userAgent;

  useEffect(() => {
    setOS(parser.setUA(userAgent).getResult().os.name);
    console.log(os);
  }, [parser, os, userAgent]);
  return (
    <>
      {/* <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container  d-flex justify-content-between">
          <div className="logo">
            <a href="/">
              <img className="img" src={logo} alt="logo" />
            </a>
          </div>

          <nav id="navbar" className="navbar">
            <ul id="course">
              <li>
                <a className={`nav-link scrollto ${home}`} href="/">
                  HOME
                </a>
              </li>
              <li className="dropdown">
                <a
                  id="about-submenu"
                  onClick={() => toggleDropdown("about-submenu")}
                  className={`${about}`}
                >
                  <span>ABOUT QA TEK</span> <i class="fas fa-caret-down"></i>
                </a>
                <ul className="course-mobile">
                  <li>
                    <a href="/about-us">
                      <span className="sub-transition">ABOUT US</span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us">
                      <span className="sub-transition">CONTACT US</span>
                    </a>
                  </li>
                  <li>
                    <a href="/team">
                      <span className="sub-transition">OUR TEAM</span>
                    </a>
                  </li>

                  <li>
                    <a href="/instructor">
                      <span className="sub-transition">OUR INSTRUCTORS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mediaandgallery">
                      <span className="sub-transition">MEDIA & GALLERY</span>
                    </a>
                  </li>
                  <li>
                    <a href="/career">
                      <span className="sub-transition">CAREER</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a
                  id="courses-submenu"
                  onClick={() => toggleDropdown("courses-submenu")}
                  className={`${course}`}
                >
                  <span>COURSES & REGISTRATION</span>{" "}
                  <i class="fas fa-caret-down"></i>
                </a>
                <ul>
                  <li>
                    <a href="/courses">
                      <span className="sub-transition">COURSES</span>
                    </a>
                  </li>

                  <li>
                    <a href="/registration">
                      <span className="sub-transition">REGISTRATION</span>
                    </a>
                  </li>
                  <li>
                    <a href="/trial-class">
                      <span className="sub-transition">JOIN TRIAL CLASS</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a href="/payment" className={`nav-link scrollto ${payment}`}>
                  PAYMENT
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${lms}`}
                  href="https://qateksolutions-lms.com/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LMS
                </a>
              </li>
            </ul>
            <i
              id="nav-button"
              className="fas fa-bars mobile-nav-toggle"
              onClick={() => toggleMenu()}
            ></i>
          </nav>

          <ul className="social-head list-unstyled">
            <li>
              <a
                href="https://www.facebook.com/QA-Tek-Solutions-Inc-112681010483577"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fab fa-facebook"></i>
              </a>
            </li>
            <li> */}
      {/* {`banner large ${active ? "active" : ""}------------------------------------------------------------------------------- */}

      {/* <a
                href={
                  os === "Android" || os === "iOS"
                    ? "https://wa.me/13472591930?"
                    : "https://web.whatsapp.com/send?phone=13472591930&text="
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fab fa-whatsapp"></i>
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UCiqY0yp-sjdePPg-KsmRx_A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fab fa-youtube"></i>
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/qa-tek-solutions-inc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </header> */}

      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <div className="logo">
            <a href="/">
              <img className="img" src={logo} alt="logo" />
            </a>
          </div>

          <nav id="navbar" className="navbar">
            <ul id="course" className="list-unstyled">
              <li>
                <a className={`nav-link scrollto ${home}`} href="/">
                  HOME
                </a>
              </li>
              <li className="dropdown">
                <a
                  id="about-submenu"
                  onClick={() => toggleDropdown("about-submenu")}
                  className={`${about}`}
                >
                  <span>ABOUT QA TEK</span> <i className="fas fa-caret-down"></i>
                </a>
                <ul className="sub-menu course-mobile">
                  <li>
                    <a href="/about-us">
                      <span className="sub-transition">ABOUT US</span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us">
                      <span className="sub-transition">CONTACT US</span>
                    </a>
                  </li>
                  <li>
                    <a href="/team">
                      <span className="sub-transition">OUR TEAM</span>
                    </a>
                  </li>
                  <li>
                    <a href="/instructor">
                      <span className="sub-transition">OUR INSTRUCTORS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mediaandgallery">
                      <span className="sub-transition">MEDIA & GALLERY</span>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/career">
                      <span className="sub-transition">CAREER</span>
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="dropdown">
                <a
                  id="courses-submenu"
                  onClick={() => toggleDropdown("courses-submenu")}
                  className={`${course}`}
                >
                  <span>COURSES & REGISTRATION</span>{" "}
                  <i className="fas fa-caret-down"></i>
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="/courses">
                      <span className="sub-transition">COURSES</span>
                    </a>
                  </li>
                  <li>
                    <a href="/registration">
                      <span className="sub-transition">REGISTRATION</span>
                    </a>
                  </li>
                  <li>
                    <a href="/trial-class">
                      <span className="sub-transition">JOIN TRIAL CLASS</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/payment" className={`nav-link scrollto ${payment}`}>
                  PAYMENT
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${lms}`}
                  href="https://qateksolutions-lms.com/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LMS
                </a>
              </li>
            </ul>
            <i
              id="nav-button"
              className="fas fa-bars mobile-nav-toggle"
              onClick={() => toggleMenu()}
            ></i>
          </nav>

          {/* <ul className="social-head list-unstyled">
            <li>
              <a
                href="https://www.facebook.com/QA-Tek-Solutions-Inc-112681010483577"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href={
                  os === "Android" || os === "iOS"
                    ? "https://wa.me/13472591930?"
                    : "https://web.whatsapp.com/send?phone=13472591930&text="
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCiqY0yp-sjdePPg-KsmRx_A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/qa-tek-solutions-inc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul> */}

          <ul className="social-head list-unstyled">
            <li>
              <a
                href="https://www.facebook.com/QA-Tek-Solutions-Inc-112681010483577"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <span className="sr-only">Facebook</span>
                <i className="fab fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                href={
                  os === "Android" || os === "iOS"
                    ? "https://wa.me/13472591930?"
                    : "https://web.whatsapp.com/send?phone=13472591930&text="
                }
                target="_blank"
                rel="noopener noreferrer"
                aria-label="WhatsApp"
              >
                <span className="sr-only">WhatsApp</span>
                <i className="fab fa-whatsapp" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCiqY0yp-sjdePPg-KsmRx_A"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <span className="sr-only">YouTube</span>
                <i className="fab fa-youtube" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/qa-tek-solutions-inc"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <span className="sr-only">LinkedIn</span>
                <i className="fab fa-linkedin" aria-hidden="true"></i>
              </a>
            </li>
          </ul>

        </div>
      </header>

    </>
  );
}

export default Navbar;
