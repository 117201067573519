import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  SwiperCore,
  Autoplay,
} from "swiper";
import "swiper/css/bundle";
import "./Stories.css";
import person from "./assets/person.jpg";
import nafiz from "./assets/nafiz.jpeg";

function Stories() {
  return (
    <div className="Story" data-aos="fade-up">
      <section id="testimonials" className="testimonials section-bg" role="region" aria-label="Testimonials">
        <div className="section-title">
          <h2>Success Stories</h2>
        </div>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={2}
          navigation
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <div className="testimonial-wrap">
              <div className="testimonial-item person">
                <img src={person} className="testimonial-img" alt="" />
                <h3>Md Alimuzzaman</h3>
                <h4>QA Engineer</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Would definitely recommend QA TEK Solutions. They have helped
                  me through the entire process starting from taking the
                  training to landing a job as a QA Engineer. All the
                  instructors are very knowledgeable and professional. One thing
                  I like the most about their teaching style is that they break
                  the problems down into small steps so one can easily
                  understand them. Course contents are very rich and give a
                  broad understanding of real-life work experience. I’m
                  currently working full-time and am further progressing in my
                  tech career. Thanks, QA TEK team!
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-wrap">
              <div className="testimonial-item">
                <img src={nafiz} className="testimonial-img" alt="" />
                <h3>Nafiz Chowdhury</h3>
                <h4>QA Engineer</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  QA TEK provided extraordinary service. I’m so glad that I made
                  the decision to learn Software Testing with them. All the
                  trainers are truly knowledgeable, expert, and passionate.
                  Their teaching method is not only flawless, but the material
                  covered in the course is thorough and valuable for both
                  beginners as well as pro. From preparing the resume to
                  teaching me all the valuable tips and tricks for the
                  interview, QA TEK has been with me every step of the way.
                  Within six months I got my dream job and right now I’m working
                  full time with benefits and other perks. Highly recommend the
                  course and the company.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </div>

  );
}

export default Stories;
