import "./App.css";
import "./News.css";
import Navbar from "./Navbar";
// import Navigation from "./Navigation";
import Slider from "./Slider";
import AOS from "aos";
import "aos/dist/aos.css";
import News from "./News";
import About from "./About";
import Services from "./Services";
import Course from "./Course";
import Quotes from "./Quotes";
import Stories from "./Stories";
import Footer from "./Footer";

function Homepage() {
  AOS.init();
  return (
    <>
      <Navbar home="active" />

      <Slider />
      <News />
      <About />
      <Services />
      <Course />
      <Stories />
      <Quotes />
      <Footer />
    </>
  );
}

export default Homepage;
