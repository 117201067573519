import Navbar from './Navbar';
import Footer from './Footer';
import './Team.css';
import RifatHalim from './assets/Rifat-Halim.JPG';
import FaridUddin from './assets/Farid-Uddin.jpeg';
import ShajibKhadem from './assets/Shajib-khadem.jpg';
import anamika from './assets/anamika.jpeg';

import salman from './assets/salman.jpeg';
import shoab from './assets/shoab.jfif';
import redowan from './assets/redowan.jpg';
import logo from './assets/logo.png';

function Team() {
  return (
    <>
      <Navbar about="active" />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="breadcrumb-hero">
          <div class="container">
            <div class="breadcrumb-hero">
              <a href="#">
                <img src={logo} className="hero-image" alt="" />
              </a>
              <h3>QA Tek Solutions team</h3>
              <p>Door Steps to IT World</p>
            </div>
          </div>
        </div>
      </section>

      <div id="team" className="our-team-area area-padding">
        <div className="container ">
          <div className="section-title text-center">
            <h2>Meet our team</h2>
          </div>
          <div className="team-qoutes">
            <p>
              " Coming together is a beginning. Keeping together is progress.
              Working together is success "
            </p>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12"></div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a>
                    <img
                      src={RifatHalim}
                      className="img-fluid team-image"
                      alt=""
                    />
                  </a>
                  {/* <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="team-content text-center">
                  <h4>Rifat M Halim</h4>
                  <p>President & Instructor</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a>
                    <img
                      src={FaridUddin}
                      className="img-fluid team-image"
                      alt=""
                    />
                  </a>

                  {/* <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="team-content text-center">
                  <h4>Mohammad Uddin</h4>
                  <p>Vice President & Instructor​</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a>
                    <img
                      src={ShajibKhadem}
                      className="img-fluid team-image"
                      alt=""
                    />
                  </a>
                  {/* <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="team-content text-center">
                  <h4>Shajib Khadem</h4>
                  <p>Technical Adviser</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a>
                    <img
                      src={anamika}
                      className="img-fluid team-image"
                      alt=""
                    />
                  </a>
                  {/* <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="team-content text-center">
                  <h4>Anamica Karmaker </h4>
                  <p>HR & Finance Specialist</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a>
                    <img
                      src={redowan}
                      className="img-fluid team-image"
                      alt=""
                    />
                  </a>
                </div>
                <div className="team-content text-center">
                  <h4>Redoanul Hoque</h4>
                  <p>Business Support Manager</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Team;
