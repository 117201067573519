import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import YoutubeEmbed from "./YoutubeEmbed";
import "./YoutubeEmbed.css";
import "aos/dist/aos.css";
import "./AboutPage.css";
import RifatHalim from "./assets/Rifat-Halim.JPG";
import FaridUddin from "./assets/Farid-Uddin.jpeg";
import ShajibKhadem from "./assets/Shajib-khadem.jpg";

function Aboutpage() {
  AOS.init();
  return (
    <>
      <Navbar about="active" />
      <section id="about-us" className="about-us">
        <div className="container">
          <div className="about-us-title">
            <span>ABOUT US</span>
            <h1>ABOUT US</h1>
            <p>
              If you want to learn more about us, you’re in the right place.
              Read to learn how we train to help you grow in future.
            </p>
          </div>

          <div className="row">
            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="fas fa-dove"></i>
                </div>
                <h4>
                  <a href="">Mission</a>
                </h4>
                <p>
                  Build expert workers to validate new software, check the
                  enhancements or upgrades.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="fas fa-eye"></i>
                </div>
                <h4>
                  <a href="">Vision</a>
                </h4>
                <p>Customer focused, agile. Always adapt to new technology.</p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="fas fa-bullseye"></i>
                </div>
                <h4>
                  <a href="">Goal</a>
                </h4>
                <p>
                  Provide quality training , prepare students for the future job
                  market in the software industries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="our-story" className="our-story">
        <div className="container" data-aos="fade-up">
          <div className="row content">
            <h1 className="heading-story">OUR STORY</h1>
            <div className="col-lg-6" data-aos="fade-right">
              <p>
                <strong>QA Tek Solutions</strong> was founded in February 2016
                by Rifat Halim and Mohammad Uddin with the inspiration to help
                the Bangladeshi Communities. As an immigrant from Bangladesh, we
                all come to the USA with great hope but not all of us got the
                proper guidance and opportunities to be successful.
              </p>
              <p>
                There is a tremendous need for skilled IT professionals in the
                USA job market in different platforms such as Technology, Health
                Insurance, Retail, you name it; everywhere you need skilled and
                trained workers. But due to the lack of skills in IT, a big
                number of people from Bangladeshi communities are deprived to
                grab those opportunities and ended up working in odd jobs.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
              <p>
                The goal of our company is to help those people who would like
                to pursue their career in IT industries. Software QA (Quality
                Assurance) is such a field where it is not required to have a
                Bachelors’ Degree in IT/Computer Science. People from a
                different discipline are also capable to foresee their career in
                the Software Testing field as long as they have the rights
                skills set to perform the job.
              </p>
              <p>
                Software QA/Testing has become an integral part of the software
                life cycle. Every day there is new software is being created,
                enhanced, and upgraded, and there will be the same cycle in the
                future too. Software testing helps to identify errors, gaps, or
                missing requirements contrary to the actual requirements.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="about-us-video">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <YoutubeEmbed embedId="2CyNbfMKzok" />
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Come meet us up close</h2>
          </div>

          <div className="row content">
            <div
              className="col-md-5"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={RifatHalim} className="img-fluid about-image" alt="" />
            </div>
            <div
              className="col-md-7 pt-4"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h1 className="name">Rifat M Halim</h1>
              <h3 className="position">President & Instructor</h3>
              <p className="fst-italic">
                Sr. Software Engineer In Test in the profession. He has been in
                IT Industries for many years. He is an expert Automation
                Engineer and working in different domains over the years. His
                style of teaching automation topics is significant and up to the
                demands of the current job market.
              </p>
            </div>
          </div>

          <div className="row content">
            <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
              <img src={FaridUddin} className="img-fluid about-image" alt="" />
            </div>
            <div
              className="col-md-7 pt-5 order-2 order-md-1"
              data-aos="fade-right"
            >
              <h1 className="name">Mohammad Uddin</h1>
              <h3 className="position">Vice President & Instructor​</h3>
              <p className="fst-italic">
                SQA Engineer with Lowes Companies, one of the largest retail
                companies in the US. He has been in IT Industries for quite a
                bit of time. He has been an expert SQA Engineer and working in
                different domains. His style of teaching software testing is
                simple and deep.
              </p>
            </div>
          </div>

          <div className="row content">
            <div className="col-md-5" data-aos="fade-right">
              <img
                src={ShajibKhadem}
                className="img-fluid about-image"
                alt=""
              />
            </div>

            <div className="col-md-7 pt-5" data-aos="fade-left">
              <h1 className="name">Shajib Khadem</h1>
              <h3 className="position">Technical Adviser</h3>
              <p className="fst-italic">
                Shajib holds a Technical Advisor position in QA Tek Solutions
                Inc. besides engineering jobs and software development business
                globally. He has vast experience in telecommunications,
                automation, IT solutions, customer service & assurance, process
                improvements, change management, and professional training
                development from the USA, Australia, and the Netherlands.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Aboutpage;
