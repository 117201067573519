import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <section className="section services-section text-center" id="services" role="region" aria-label="Services Section">
      <div className="container">
        <header className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2>What We Offer</h2>
            </div>
          </div>
        </header>
        <div className="row">
          <div className="col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-desktop" />
              </div>
              <div className="feature-content text-center">
                <h3>Professional IT Courses</h3>
                <p>
                  We provide interactive and demanding IT courses to students who do not have any prior IT experiences with flexible schedules, especially on the weekends.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-comment" />
              </div>
              <div className="feature-content">
                <h3>Resume Build Up</h3>
                <p>
                  Concise and comprehensive resume helps to catch the attention of employers and stand out from the crowd.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-image" />
              </div>
              <div className="feature-content">
                <h3>Job Assistance</h3>
                <p>
                  At the end of the course, we provide job assistance to the students which include bootcamp, interview preparation, introduction to the IT recruiters, and posting the resume on the job portal.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-th" />
              </div>
              <div className="feature-content">
                <h3>Website Design</h3>
                <p>
                  Looking for affordable website design? QA TEK Solutions can help you build a responding, high-performing, and lead-driving website with guaranteed satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="feature-box-1">
              <div className="icon">
                <i className="fa fa-cog" />
              </div>
              <div className="feature-content">
                <h3>IT Consultancy</h3>
                <p>
                  Whether you need a help desk outsourcing solution or remote network monitoring, our expert IT consulting firm is here to assist. Let QA TEK SOLUTIONS help your business grow through our certified, strategic IT experts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default Services;
