import React from "react";
import "./GalleryMainPage.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Imagegallery from "./assets/ImageGallery.jpg";
import events from "./assets/events.jpg";
import Media from "./assets/Media.jpg";

const GalleryMainPage = () => {
  return (
    <>
      <Navbar />
      <section>
        <div className="container">
          <div className="section-title gallery-title text-center">
            <h2>GALLERY & MEDIA</h2>
          </div>
        </div>
        <section className="details-card">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="card-content">
                  <div className="card-img">
                    <img src={Imagegallery} alt="" />
                    <span id="imagegallery">
                      <h4>Image Gallery</h4>
                    </span>
                  </div>
                  <div className="card-desc">
                    <h3>Image Gallery</h3>
                    <p>View our latest pictures.</p>
                    <a href="/imagegallery" className="btn btn-card">
                      View
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-content">
                  <div className="card-img">
                    <img src={events} alt="" />
                    <span>
                      <h4>Events</h4>
                    </span>
                  </div>
                  <div className="card-desc">
                    <h3>Events</h3>
                    <p>View our latest events.</p>
                    <a href="/events" className="btn btn-card">
                      View
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-content">
                  <div className="card-img">
                    <img src={Media} alt="" />
                    <span>
                      <h4>Media</h4>
                    </span>
                  </div>
                  <div className="card-desc">
                    <h3>Media</h3>
                    <p>View our latest media.</p>
                    <a href="/media" className="btn btn-card">
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default GalleryMainPage;
