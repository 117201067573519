import React from 'react';
import slider01 from './assets/pexels-anna-nekrashevich-6476124.jpg';
import slider02 from "./assets/pythonCourse.jpeg"
import slider03 from "./assets/SoftwareTestingCourse.jpg"



import './Slider.css';
import Carousel from 'react-bootstrap/Carousel';

function Slider() {
  return (
    <section className="slider-home" role="region" aria-label="Slider Section">
      <Carousel controls={true} indicators={false}>
        <Carousel.Item>
          <figure>
            <img
              className="w-100"
              src={slider01}
              alt="First slide"
            />
          </figure>
          <Carousel.Caption>
            <header>
              <h2>WELCOME TO QA TEK SOLUTIONS</h2>
            </header>
            <p>
              We are a leading global IT training provider. We provide
              quality training and prepare students for the future job market
              in the software industry.
            </p>

            <div className="slider-btn">
              <a href="#services">
                <button className="btn btn-1">Our Services</button>
              </a>
              <a href="/courses">
                <button className="btn btn-2">Courses</button>
              </a>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <figure>
            <img
              className="w-100"
              src={slider02}
              alt="Second slide"
            />
          </figure>

          <Carousel.Caption>

            <a href="/data-analysis-with-python">
              <div className="d-flex justify-content-center">
                <img
                  src={slider02}
                  alt="Second slide"
                  style={{ opacity: 0 }}
                />
              </div>
            </a>

          </Carousel.Caption>

        </Carousel.Item>

        <Carousel.Item>
          <figure>
            <img
              className="w-100"
              src={slider03}
              alt="Second slide"
            />
          </figure>

          <Carousel.Caption>

            <a href="/software-testing-with-selenium">
              <div className="d-flex justify-content-center">
                <img
                  src={slider03}
                  alt="Second slide"
                  style={{ opacity: 0 }}
                />
              </div>
            </a>

          </Carousel.Caption>

        </Carousel.Item>


      </Carousel>
    </section>
  );
}

export default Slider;
