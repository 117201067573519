import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./ContactPage.css";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";

const Result = () => {
  return <p>Your message has been sent successfully.</p>;
};

export const ContactPage = () => {
  const form = useRef();
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vkkik1a",
        "template_2hpxit3",
        form.current,
        "VvncC_zmTTiWi-pKP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };
  AOS.init();
  return (
    <>
      <Navbar about="active" />
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>CONTACT</h2>
          </div>
          <div className="row" data-aos="fade-right" data-aos-delay={100}>
            <div className="col-lg-5">
              <div className="info-box mb-4">
                <i className="fas fa-map-marker-alt" />
                <h3>New York Address</h3>
                {/* <p>89-16 175 St., CF-4, Jamaica, NY 11432​</p> */}
                <p>118-35 Queens Blvd, Suite 400, Forest Hills, NY 11375​</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-box  mb-4">
                <i className="fas fa-phone-alt" />
                <h3>Call Us</h3>
                <p>NY- (347) 259-1930, NY- (347) 658-9284</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i className="far fa-envelope" />
                <h3>Email Us</h3>
                <p>admin@qateksolutions.com</p>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-left" data-aos-delay={100}>
            {/* <div className="col-lg-6">
              <div className="info-box mb-4">
                <i className="fas fa-map-marker-alt" />
                <h3>Virginia Address</h3>
                <p>​6 Pidgeon Hill Drive, Suite 220, Sterling, VA 20165</p>
              </div>
            </div> */}

            {/* <div className="col-lg-6 col-md-6">
              <div className="info-box  mb-4">
                <i className="fas fa-phone-alt" />
                <h3>Call Us</h3>
                <p>VA- (571) 457-0371</p>
              </div>
            </div> */}
            {/* <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i className="fas fa-globe" />
                <h3>Our Website</h3>
                <p>www.qateksolutions.com</p>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="map-div ">
                <div className="form-title">
                  <h2>NEW YORK LOCATION</h2>
                </div>
                <iframe
                  className="map mb-4 mb-lg-0"
                  src="https://maps.google.com/maps?q=118-35%20Queens%20Blvd.,%20Suite%20400,%20Forest%20Hills,%2011375&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  allowFullScreen
                />
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay={100}>
            {/* <div className="col-lg-6">
              <div className="map-div ">
                <div className="form-title">
                  <h2>VIRGINIA LOCATION</h2>
                </div>
                <iframe
                  className="map mb-4 mb-lg-0"
                  src="https://maps.google.com/maps?q=%E2%80%8B6%20Pidgeon%20Hill%20Drive,%20Suite%20220,%20Sterling,%20VA%2020165&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  allowFullScreen
                />
              </div>
            </div> */}
            <div className="col-lg-12 mt-5">
              <form ref={form} onSubmit={sendEmail} className="php-email-form">
                <div className="row">
                  <div className="form-title">
                    <h2>LEAVE A MESSAGE FOR US</h2>
                  </div>
                  <div className="col form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows={5}
                    placeholder="Message"
                    required
                    defaultValue={""}
                  />
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
                <div className="row message-success">
                  {result ? <Result /> : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ContactPage;
