import Navbar from "./Navbar";
import Footer from "./Footer";
import { Tabs, Tab } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import "./payment.css";
import payment from "./assets/payment.jpg";

function Payment() {
  AOS.init();
  return (
    <>
      <Navbar payment="active" />
      <div id="contact" className="registration-form">
        <div className="left discount justify-content-center">
          <h2>We have multiple payment options.</h2>
        </div>
        <div className=" d-flex align-items-stretch right ">
          <a href="#">
            <img src={payment} className="payment-image" alt="" />
          </a>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center" data-aos="fade-right">
          <div className="section-title ">
            <h2>Payment Options</h2>
          </div>
        </div>
        <div className="row justify-content-center" data-aos="fade-down">
          <div className=" link">
            <p>
              Follow your preferred procedure to complete payment. For any query
              <a href="/contact-us"> Contact us. </a>
            </p>
          </div>
        </div>

        <div className="row" data-aos="fade-up">
          <div className="col-12">
            <section className="payment">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="tab-menu"
              >
                <Tab className="tab-item" eventKey="home" title="Zelle">
                  <p>
                    <strong>QA TEK SOLUTIONS INC</strong>
                  </p>
                  <p>Email: qateksolutions@gmail.com</p>
                </Tab>
                <Tab className="tab-item" eventKey="profile" title="Cash">
                  <p>
                    <strong>
                      Please deposit the course fees to the following accounts:
                    </strong>
                  </p>
                  <p>
                    Bank: <strong>Wells Fargo</strong>
                  </p>
                  <p>
                    Name: <strong>QA Tek Solutions Inc.</strong>
                  </p>
                  <p>
                    Account Number: <strong>9846073220</strong>
                  </p>
                </Tab>
                <Tab
                  className="tab-item"
                  eventKey="contact"
                  title="Credit Card / eCheck"
                >
                  <p>
                    <strong>QA Tek Solutions</strong> will not charge extra fees
                    for using a Credit Card to pay the course fees.
                  </p>
                  <p>
                    Please check with your Credit Card Company or local bank for
                    exact amount including conversion, foreign transaction or
                    any other fees.
                  </p>
                  <button type="button" className="btn btn-outline-info button">
                    <a
                      href="https://secure.cpacharge.com/pages/qateksolutions/payments"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PAY WITH CREDIT CARD
                    </a>
                  </button>
                </Tab>
                <Tab className="tab-item" eventKey="check" title="Check">
                  <p>
                    Please write a check payable to
                    <strong> QA Tek Solutions Inc</strong> and mail to the
                    following address:
                  </p>
                  <strong>QA Tek Solutions Inc.</strong>
                  <p>c/o: Mohammad Uddin,</p>
                  <p>150-28 119th Avenue, Jamaica, NY 11434</p>
                </Tab>
              </Tabs>
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Payment;
